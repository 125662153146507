<template>
    <div class="container text-center">
        <div class="row">
            <div class="d-flex">
                <img :src="require('../assets/img/ghs-logo.png')" style="width: 100px;" :class="[{'mx-auto': position == 'center' || position == null}]" alt="">
            </div>          
        </div>  
    </div>
</template>

<script>
    export default {
        name: 'Logo',
        props: ['position']
    }
</script>