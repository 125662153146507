<template>
    <div id="wrapper" class="bg-white pt-4">
        <div class="container">
            
            <logo class="pt-4 ms-4 mb-3" :position="'left'"></logo>

            <div class="row">
                <h1 class="text-orange ms-4 fw-bold">Let's get to know you better!</h1>
                <p class="ms-4">Tell us a bit more about yourself </p>
            </div>

            <status v-model:status_data=status_data></status>

            <form id="form" action="#" @submit.prevent="handleSubmit()">
                <div class="card-body row border-bottom-0 p-4 pt-2">

                    <div class="row mt-4" id="cpassword-row">
                        <label for="address" class="fw-bold mb-1">Enter your Address</label>  
                        <div class="input-group">
                            <span class="input-group-text" >
                                <font-awesome-icon id="lock" :icon="['fa', 'id-card']" />
                            </span>

                            <input id="address" type="address" class="form-control" v-model="data.address" placeholder="Enter your address." required>
                        </div>
                    </div>

                    <div class="col-md-6 col-sm-12">
                        <div class="row mt-4" id="cpassword-row">
                            <label for="idno" class="fw-bold mb-1">Enter your National ID</label>

                            <div class="input-group">
                                <span class="input-group-text">
                                    <font-awesome-icon id="lock" :icon="['fa', 'id-card']" />
                                </span>

                                <input id="idno" type="idno" class="form-control" v-model="data.idno" placeholder="Enter your National ID Number." required>
                            </div>
                        </div>

                        <div class="form-row mt-4">
                            <label for="dob" class="fw-bold mb-1">Enter your Date of Birth</label>                                       
                            <div class="input-group">
                                <span class="input-group-text border-right-0">
                                    <font-awesome-icon id="eye" :icon="['fa', 'clock']" />
                                </span>
                                
                                <input id="dob" type="date" class="form-control no-border" v-model="data.dob" placeholder="Enter your Date of Birth" required>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-6 col-sm-12">
                        <div class="form-row mt-4">
                            <label for="gender" class="fw-bold mb-1">Select your Gender</label>                                    
                            
                            <div class="input-group">
                                <span class="input-group-text border-right-0">
                                    <font-awesome-icon id="eye" :icon="['fa', 'venus-mars']" />
                                </span>

                                <select name="gender" id="gender" class="form-select" v-model="data.gender">
                                    <option value="name">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </div>
                        </div>                                

                        <div class="form-row mt-4" id="password-row">
                            <label for="marital-status" class="fw-bold mb-1">Select your Marital Status</label>            
                                                        
                            <div class="input-group">
                                <span class="input-group-text" >
                                    <font-awesome-icon id="lock" :icon="['fa', 'ring']" />
                                </span>

                                <select name="marital-status" id="marital-status" class="form-select" v-model="data.marital">
                                    <option value="single">Single</option>
                                    <option value="married">Married</option>
                                    <option value="divorced">Divorced</option>
                                </select>
                            </div>
                        </div>   
                    </div>

                    <div class="row mt-2" id="login">
                        <div class="col">
                            <button class="btn bg-orange text-white btn-sm mt-4 w-100 text-uppercase fw-bold pt-2 pb-2" type="submit">
                                <span style="letter-spacing: 2px">Submit</span> <font-awesome-icon class="icon ms-1 me-1" :icon="['fa', 'angle-right']" />
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <main-footer :center=true></main-footer>
    </div>
</template>

<style scoped lang="scss">
    @import '../../assets/scss/base/_base.scss';
    @import '../../assets/scss/base/_fonts.scss';
    @import '../../assets/scss/helpers/breakpoints.scss';
    
</style>

<script>
    import Status from '../../components/Status';
    import Logo from '../../components/Logo';
    import MainFooter from '../../components/Footer';

    export default {
        name: 'Kyc',
        props: {
            user_id: {
                required: true,
                type: String
            }
        },
        components: {
            Status,
            Logo,
            MainFooter
        },
        data() {
            return {
                data: {
                    dob: null,
                    gender: null,
                    address: null,
                    marital: null,
                    idno: null,
                    user_id: this.user_id
                },
                status_data: {
                    "success": false,
                    "success_message": '',
                    "error": false,
                    "error_message": '',
                },
                loading: false
            }
        },
        methods: {
            handleSubmit() {
                this.axios.post('api/kyc', this.data).then((response) => {
                    this.status_data.loading = false
                    this.status_data.success = true
                    this.status_data.error = false
                    this.status_data.success_message = 'We got it! Thanks!'
                    console.log(response);

                    setTimeout(() => {
                        this.$router.push({name: 'Home'});
                    }, 2000);
                }).catch((error) => {
                    this.status_data.loading = false
                    this.status_data.success = false
                    this.status_data.error = true

                    this.status_data.error_message = error.response.data.data
                })
            }, 
            validatePassword() {
                if(this.data.password == this.data.cpassword) {
                    if(this.data.password.length > 7) {
                        return true
                    } else {
                        return {
                            status: false,
                            message: "Password length is too short."
                        }
                    }
                } else {
                    return {
                        status: false,
                        message: "Your passwords do not match."
                    }                    
                }
            },
            seePassword(id) {
                if(document.querySelector('#' + id).type == 'password') {
                    document.querySelector('#' + id).type = 'text'
                } else {
                    document.querySelector('#' + id).type = 'password'
                }
            },
            hideAlert() {
                if(this.success == true) {
                    this.success = false
                } else if(this.error == true) {
                    this.error = false
                }
            }
        }
    }
</script>